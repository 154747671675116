<template>
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_24c8f46012a25c89')" append-to-body :visible.sync="dialogShow" :direction="'rtl'"
		size="500px">
		<div v-loading="loading">
			<!-- <div class="filterCon" style="">
				<ul class="filterConList" style="text-align: left;">
					<li>
						<span>{{$t('i18nn_54672b9140a3afe0')}}</span>
						<el-select v-model="printerVal" :placeholder="$t('hytxs0000100')" filterable clearable
							style="margin-left: 10px;" @change="changePrinter">
							<el-option v-for="(item, index) in printerList" :key="index" :label="item.name"
								:value="item.value"></el-option>
						</el-select>
					</li>
				</ul>
			</div> -->
			<!-- <div class=""> -->

				<!-- "whNo": this.row.whNo, //仓库",
				"sysOrderNo": this.row.sysOrderNo, //"系统单号",
				"relationNo": null, //"关联号",
				"bizType": this.row.bizType, //"业务类型",
				"opType": null, //"操作类型",
				"opTime": null, //"时间",
				"parentId": this.row.id, //"业务数据ID",
				"recordAmtDtoList": [] -->
				<!-- <el-card>
					<el-descriptions :title="$t('i18nn_aae671b49dde2bba')">
						<el-descriptions-item :label="$t('c944a6686d996ab3')">{{form.whNo}}</el-descriptions-item>
						<el-descriptions-item :label="$t('hytxs0000060')">{{form.cusName}}</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_c617dfa4c0b5ecb4')">{{form.sysOrderNo}}</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_9c3b3985298f22f1')">{{form.relationNo}}</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_76dc341edac65885')">{{form.bizType}}</el-descriptions-item>
					</el-descriptions>
				</el-card> -->

				<!-- <el-card>

					<FeeModelById :id="row.id"></FeeModelById>
				</el-card> -->
				<!-- <el-tabs v-model="detTabActiveName" type="border-card"> -->
					<!-- <el-tab-pane label="" name="first" v-loading="loading"> -->
						<!-- <span slot="label"><i class="el-icon-plus"></i> <span>{{$t('i18nn_3215d0a40f17773d')}}</span></span> -->
						
						<el-form ref="form" :size="''" :rules="formRules" :model="form" label-width="120px"
							style="width: 100%">
							
							<el-form-item :label="$t('c944a6686d996ab3')" prop="" required>
								<whNoSelect ref="whNoSelect" :size="''" @changeData="changWhNo"></whNoSelect>
							</el-form-item>
							
							<el-form-item :label="'盘点方式'" prop="" required>
								<el-select filterable clearable v-model="form.invType" :placeholder="$t('2ad108ab2c560530')">
									<el-option v-for="item in selectOption.biz_wh_inv_task_type" :key="item.code"
										:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
											<span class="sel_option_code" style="">{{ item.code }}</span>
										</div>
									</el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item :label="$t('i18nn_5c5140f8913c5819')" prop="" required>
								<el-input :size="''" type="text" v-model="form.title"></el-input>
							</el-form-item>

							<!-- <div>
								<el-form-item :label="$t('i18nn_82b5bd967d494be3')" prop="opType">
									<el-select filterable clearable disabled v-model="form.opType" :placeholder="$t('2ad108ab2c560530')">
										<el-option v-for="item in selectOption.wh_op_type" :key="item.code"
											:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item :label="$t('2594059036dfede5')" prop="opTime" required>
									<el-date-picker v-model="form.opTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="12:00:00"
										:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
								</el-form-item>
							</div>
							<div>
								<el-table :size="'small'" :data="form.recordAmtDtoList" style="width: 100%" size="small">
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('7b1c2b1adc920d9c')"></el-table-column>

									<el-table-column prop="feeType" :label="$t('46c3f6e0f657e7a3')">
										<template slot-scope="scope">
											<el-select :size="'small'" filterable clearable v-model="scope.row.feeType" :placeholder="$t('2ad108ab2c560530')">
												<el-option v-for="item in selectOption.feetypeList" :key="item.feeType"
													:label="$Utils.i18nKeyText(item,'feeTypeName')" :value="item.feeType">
													<div class="sel_option_s1" style="">
														<span class="sel_option_name">
															{{ $Utils.i18nKeyText(item,'feeTypeName') }}</span>
														<span class="sel_option_code" style="">{{ item.feeType }}</span>
													</div>
												</el-option>
											</el-select>

										</template>
									</el-table-column>
									<el-table-column prop="feeAmt" :label="$t('d7315fb8114eb446')">
										<template slot-scope="scope">
											<el-input-number :size="'small'" v-model="scope.row.feeAmt"></el-input-number>
										</template>
									</el-table-column>
									<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
										<template slot-scope="scope">
											<el-input :size="'small'" type="textarea" v-model="scope.row.remark" :maxlength="1000" show-word-limit></el-input>
										</template>
									</el-table-column>
								</el-table>
							</div> -->


						</el-form>

						<div style="margin: 10px 0; text-align: center;">
							<el-button type="primary" icon="el-icon-tickets" style="width: 220px;"
								@click="submitForm('form')">{{$t('4ce9979bfb6576d9')}}</el-button>

						</div>

						<!-- </el-card> -->
					<!-- </el-tab-pane> -->
					<!-- <el-tab-pane label="" name="second">
						<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_e47e0c74c02bf395')}}</span></span>

						<FeeModelById ref="FeeModelById" :id="row.id"></FeeModelById>
					</el-tab-pane> -->
				<!-- </el-tabs> -->




			<!-- </div> -->
		</div>

	</el-drawer>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	
	// import FeeModelById from '@/components/WarehouseCenter2/Other/FeeModelById.vue';
	export default {
		components: {
			// FeeModelById
			whNoSelect
		},
		props: {
			openTime: {},
			// url: {},
			row: {}
		},
		data() {
			return {
				// detTabActiveName: 'first',

				dialogShow: false,

				// printerVal: "",
				// printerList: [],
				//表格数据
				//loading,表格数据
				loading: false,
				// loading_load: false,
				//详情数据
				// loading_det: false,

				form: {
					"title":"",
					"whNo":"",
					"invType":""
				},

				formRules: {
					
				},
				selectOption: {
					feetypeList: [],
					wh_op_type: [],
				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				this.dialogShow = true;
				this.initData();
			}
		},
		activated() {

		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			//费用类型
			// this.getFeeTypeData();
			//数据字典
			getDicData(['biz_wh_inv_task_type'],
				(data) => {
					this.selectOption.biz_wh_inv_task_type = data['biz_wh_inv_task_type'];
				});

		},
		methods: {
			initData() {
				this.resetForm('form');
				this.form = {
					"title":"",
					"whNo":"",
					"invType":""
				}
				this.$nextTick(()=>{
					this.$refs.whNoSelect.init('');
				})
			},
			
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},

			// addRowData() {
			// 	this.form.recordAmtDtoList.push({
			// 		"feeType": null, //this.$t('46c3f6e0f657e7a3'),
			// 		"feeAmt": null, //this.$t('d7315fb8114eb446')
			// 		"remark": null
			// 	});
			// },
			// delRowData(event, index) {
			// 	event.stopPropagation();
			// 	this.form.recordAmtDtoList.splice(index, 1)
			// },
			//初始化打印机列表
			// initPriterList() {
			// 	let printerList = GetPrinterList();
			// 	console.log('printerList', printerList);
			// 	this.printerList = printerList;
			// },
			//保存选择的打印机
			// changePrinter(v) {
			// 	//保存此次打印机的值
			// 	// savePrtVal(this.printerVal);
			// 	// this.printerVal = v;
			// 	// this.initPagesizesList();
			// },
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						//新增
						let formData = Object.assign({}, this.form);
						this.postData(this.$urlConfig.WhInvTaskAdd, formData, data => {
							// console.log('postData', data);
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							// this.$nextTick(()=>{
							// 	this.$refs.FeeModelById.initData();
							// })
							this.dialogShow = false;
						});
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
						return false;
					}
				});
			},

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						// callback(data.data);
						if (200 == data.code) {
							callback(data.data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			// getFeeTypeData() {
			// 	this.$http
			// 		.put(this.$urlConfig.WhFeeTypeDic, {
			// 			bizType: 'PCPU', //业务类型-港口提柜
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			if (200 == data.code) {
			// 				this.selectOption.feetypeList = data.rows;
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>